import React from 'react';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom';
import Landing from './component/landing';
import Fpage from './component/fpage';
import Opage from './component/opage';
import Upage from './component/upage';
import Rpage from './component/rpage';
import ContactForm from './component/contactform';
import Login from './component/login/login';
import ForgotPassword from './component/login/forgotPassword';
import ContactView from './component/login/contactView';

function App() {
  return (
    <Router>
    <Routes>
    <Route exact path="/" element={<Landing/>} /> 
    <Route exact path="/forgotPassword" element={<ForgotPassword/>} /> 
    <Route exact path="/contactView" element={<ContactView/>} /> 
    <Route exact path="/contactform" element={<ContactForm/>} /> 
    <Route exact path="/fpage" element={<Fpage/>} /> 
    <Route exact path="/opage" element={<Opage/>} /> 
    <Route exact path="/upage" element={<Upage/>} /> 
    <Route exact path="/rpage" element={<Rpage/>} /> 
    <Route exact path="/adminpage" element={<Login/>} /> 
    </Routes>
    </Router>
  );
}

export default App;
