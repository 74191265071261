import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Fpage() {
	const navigate = useNavigate();
	let contactForm = () => {
		navigate('/contactForm', {
			state: {
			  idPass: "F",
			}
		  });
	}

	let oPageLink = () => {
		navigate('/opage', {
			state: {
			  idPass: "F",
			}
		  });
	}

	let uPageLink = () => {
		navigate('/upage', {
			state: {
			  idPass: "F",
			}
		  });
	}

	let rPageLink = () => {
		navigate('/rpage', {
			state: {
			  idPass: "F",
			}
		  });
	}
 
  return (
	<div className="App">
	<div className="root">
		<div className='pagecontent home-page'>
			
			<section className="four_homes">

				<div className="f_inner">

				<Link to={"/"}>
  <i className="fa-sharp fa-solid fa-warehouse fa-2x" style={{color: '#FFFF00', marginLeft: '20px', marginTop: '20px'}}></i> 
       </Link>

	   <div style={{padding: 0,
  marginLeft: "auto",
  marginRight:"auto",
  marginTop: "-55px",
  textAlign: "center"}}>
	   <img src={require("./images/F Complete.png")} style={{width: "75px", height: "75px", verticalAlign: "middle", marginTop: "15px", marginRight: "3px"}}/>
	   <img src={require("./images/O.png")} onClick={oPageLink} style={{width: "50px", height: "50px", verticalAlign: "text-top", marginRight: "2px", cursor: "pointer"}}/>
	   <img src={require("./images/U.png")} onClick={uPageLink} style={{width: "50px", height: "50px", verticalAlign: "text-top", marginRight: "2px", cursor: "pointer"}}/>
	   <img src={require("./images/R.png")} onClick={rPageLink} style={{width: "50px", height: "50px", verticalAlign: "text-top", cursor: "pointer"}}/>
</div>

	   <center>
	   <h4 style={{color: "white"}}>Family Extended</h4>
							<p style={{color: "white"}}>Turbocharge small business to grow dramatically. </p>
</center>


					<div className="wrapper-inner" style={{marginTop: "5spx"}}>
				

					
						<div className="f_inner_main">
				
							<div className="f_inner_div">
								<div className="f_inner_sec yellow">
									<img src={require("./images/question.svg")} />
									<p>Do you require assistance in making your growth strategy foolproof?</p>
								</div>
							</div>
							<div className="f_inner_div">
								<div className="f_inner_sec blue">
                <img src={require("./images/question.svg")} />
									<p>Are you constrained by lack of capital and access to best in class technology, analytics and management thinking to grow?</p>
								</div>
							</div>
							<div className="f_inner_div">
								<div className="f_inner_sec pink">
                <img src={require("./images/question.svg")} />
									<p>Do you need help with talent in finance/accounting, legal, market research?</p>
								</div>
							</div>
							<div className="f_inner_div">
								<div className="f_inner_sec green">
                <img src={require("./images/question.svg")} />
									<p>Do you need help make connections with delivery, logistics partners ?</p>
								</div>
							</div>
						</div>
						<div className="f_btn">
					
							<a onClick={contactForm} style={{cursor: "pointer"}}>Click here if you need more help</a>
							
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
    </div>
  );
}

export default Fpage;
