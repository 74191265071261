import React, { useState } from 'react';
import { Link} from 'react-router-dom';

const d = new Date();
let year = d.getFullYear();

function Landing() {
	const [yearFetch, setYearFetch] = useState(year);
  return (
    <div className="App">
	<div className="root">

  <div className="wrapper">	
 {/*<header className="header">
 	<div className="headercontainer">
		 
 	</div>
  </header>*/}


		<div className='pagecontent home-page'>
			<section className="four_home">
				<div className="four_home_logo">
					<div className="four_home_logo_img box-1">
					<Link to={"/fpage"}>
						<a><img src={require("./images/F.svg")} /></a>
						</Link>
						<div className="four_home_logo_content first_div">
							<h3>Family Extended</h3>
							<p>Turbocharge small business to grow dramatically. </p>
						</div>
					</div>
					<div className="four_home_logo_img box-2">
					<Link to={"/opage"}>
						<a><img src={require("./images/O.svg")}/></a>
						</Link>
						<div className="four_home_logo_content">
							<h3>Opportunity Experienced</h3>
							<p>Help college students get real experience to jump start their careers. U: uncover the true stories of your organisation to attract the right talent and attention. </p>
						</div> 
					</div>
					<div className="four_home_logo_img box-3">
					<Link to={"/upage"}>
						<a><img src={require("./images/U.svg")}/></a>
						</Link>
						<div className="four_home_logo_content">
							<h3>Untold Stories</h3>
							<p>Uncover the true stories of your organisation to attract the right talent and attention.</p>
						</div>
					</div>
					<div className="four_home_logo_img box-4">
					<Link to={"/rpage"}>
						<a><img src={require("./images/R.svg")}/></a>
						</Link>
						<div className="four_home_logo_content mt_20">
							<h3>Risk-Free Data</h3>
							<p>The right protocols and farmworks for data exchange technology to free data.</p>
						</div>
					</div>
				</div>
				<div className="four_home_head">
					<h3>TCoF MANAGEMENT CONSULTANCY LLP. </h3>
				</div>
			</section>
		</div>
<footer className="footer">
    <div className="footer_main">
    	<p>Copyright © {yearFetch} TCoF. All Rights Reserved.</p>
    </div>
</footer>
</div>
	</div>
    </div>
  );
}

export default Landing;
