import React, { useState } from 'react';
import moment from 'moment';
import { database, storage, storageRefs } from '../config/firebase';
import { ref, push, child, update } from "firebase/database";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useLocation } from 'react-router-dom';

const d = new Date();
let year = d.getFullYear();

function ContactForm() {
	const [yearFetch, setYearFetch] = useState(year);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [natureOfBusiness, setNatureOfBusiness] = useState("");
	const [enquiry, setEnquiry] = useState(""); 
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState(""); 
	const [fileName, setFileName] = useState(""); 
	const [validates, setvalidates] = useState(false);
	const locationPass = useLocation();

	console.log(locationPass.state.idPass);

	let validate = (text) => {
		console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if(reg.test(text) === false)
		{
		setEmail(text);
		setvalidates(false);
		return false;
		  }
		else {
		  setEmail(text);
		  setvalidates(true);
		}
		}

	let handleEmail = e => {
		validate(e.target.value);
	  }

	  let handleImageChange = (e) => {
		e.preventDefault();
		let file = e.target.files[0];              
		 let name = moment().unix()+file.name;
	setFileName(name);
		 const storageRef = storageRefs(storage, `contact/${name}`);
		 const uploadTask = uploadBytesResumable(storageRef, file);
	 
		 uploadTask.on("state_changed",
		   (snapshot) => {
			 const progresspercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			 setProgress(progresspercent);
		   },
		   (error) => {
			 console.log(error);
		   },
		   () => {
			 getDownloadURL(storageRef).then((downloadURL) => {
			  console.log(downloadURL);
			  setFile(downloadURL);
			  setProgress(101);
			 });
		   }
		 );
  }

	let submit = e => {
		e.preventDefault();
		if(name == ""){
		  alert("Please enter name.");
		}
		else if(contactNumber == ""){
			alert("Please enter Contact Number.");
		  }
		else if(email == ""){
			alert("Please enter email.");
		  }
		  else if(validates === false){
			alert('Email is badly formatted.');
		  }
		  else if(contactNumber.length < 10){
			alert("Please enter 10 digit Contact Number.");
		  }
		  else if(enquiry == ""){
			alert("Please enter enquiry.");
		  }
	  else{
		const newPostKey = push(child(ref(database), 'contactForm')).key;
	  
		const postData = {
name: name,
email: email,
contactNumber: contactNumber,
natureOfBusiness: natureOfBusiness,
enquiry: enquiry,
file: file,
fileName: fileName,
timeStamp: moment().unix()
		};
	  
		const updates = {};
		updates['/contactForm/' + newPostKey] = postData;
	  
		return update(ref(database), updates).then(() => {
		  alert(`Thank you \n Your form was submitted successfully!`);
		  window.location.reload();
			});
	  }
	}

let pageReturn = () =>{
if(locationPass.state.idPass == "F"){
window.location.href = "/fpage";
}
else if(locationPass.state.idPass == "O"){
	window.location.href = "/opage";
	}
	else if(locationPass.state.idPass == "U"){
		window.location.href = "/upage";
		}
		else{
			window.location.href = "/rpage";
			}
}

  return (
    <div className="App">
	<div className="root">

  <div className="wrapper">	
 <header className="header">
 	<div className="headercontainer">
		 
 	</div>
 </header>


		<div className='pagecontent home-page'>
		<section className="four_homess">
		<a onClick={pageReturn}>
		<i className="fa-solid fa-circle-arrow-left fa-3x" style={{color: '#6fc1ff', marginLeft: '20px', marginTop: '10px', cursor: 'pointer'}}></i> 
		</a>	
				<div className="f_form">
					<div className="f_form_main">
						<h3>Get in touch</h3>
	
							<div className="f_form_div">
								<input type="text" name="Name" placeholder="Name*" value={name} onChange={(event) => { setName( event.target.value ); }}/>
							</div>
							<div className="f_form_div">
								<input type="tel" minLength="9" maxLength="11" name="contactNumber" placeholder="Contact Number*" value={contactNumber} onChange={(event) => { setContactNumber( event.target.value ); }}/>
							</div>
							<div className="f_form_div">
								<input type="email" name="email" placeholder="Email Id*" value={email} autoCapitalize="none" onChange={handleEmail}/>
							</div>
							<div className="f_form_div">
								<input type="text" name="natureOfBusiness" placeholder="Nature of Business" value={natureOfBusiness} onChange={(event) => { setNatureOfBusiness( event.target.value ); }}/>
							</div>
							<div className="f_form_div">
								<input type="text" name="enquiry" placeholder="Enquiry*" value={enquiry} onChange={(event) => { setEnquiry( event.target.value ); }}/>
							</div>
							<div className="f_form_div attach">
								<label>Attach FIle</label>
								<input type="file" hidden accept=".doc,.docx,.pdf" id="file" placeholder="File" className="file" onChange={handleImageChange}/>
								<div className="new_attach_file">
                                    <input type="button" className="btn" value="Browse"/>
                                    <input type="text" name="file-name" id="file-name" className="file-name" placeholder="Select File" readonly="readonly"/>
                                  </div>
								<span>.pdf or audio file</span>
							</div>
							{progress == 0?
null
:
<>
{progress == 101?
<h6>File Uploaded. Please Submit</h6>
:
<h6>Wait for File Upload</h6>
}
</>
							}
							<div className="f_form_btn">
								<input type="submit" name="" value="Submit" onClick={submit}/>
							</div>
					
					</div>
				</div>
			</section>
		</div>
<footer className="footer">
    <div className="footer_main">
	<p>Copyright © {yearFetch} TCoF. All Rights Reserved.</p>
    </div>
</footer>
</div>
	</div>
    </div>
  );
}

export default ContactForm;
