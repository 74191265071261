import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

var config = {
  apiKey: "AIzaSyDw5ekYntuSRkipAEpUb6sxVRGQxJ3_2Uw",
  authDomain: "tcof-web.firebaseapp.com",
  databaseURL: "https://tcof-web-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tcof-web",
  storageBucket: "tcof-web.appspot.com",
  messagingSenderId: "976530677838",
  appId: "1:976530677838:web:74f19d4c9f0a461c60231f",
  measurementId: "G-7K2PY0D5NT"
};

  const app = initializeApp(config);

const db = getFirestore(app);
    const database = getDatabase(app);
    const storage = getStorage(app);
    const auth = getAuth(app);
    const storageRefs = ref;
    export {database, storage, storageRefs, db, auth};
 
