import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage, storageRefs } from '../../config/firebase';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './contactView.css';

function ContactView() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");
    const [urlData, setUrlData] = useState("");
    const [urlName, setUrlName] = useState("");
    const navigate = useNavigate();

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
  
    useEffect(() => { 
     const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
              }
              else {
                window.location.href = '/adminpage'; 
              }
            })
       }
         getUserData();
    
    },[]);

      useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]);   

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'contactForm/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.name = element.val().name;
                item.email = element.val().email;
                item.contactNumber = element.val().contactNumber;
                item.natureOfBusiness = element.val().natureOfBusiness;
                item.enquiry = element.val().enquiry;
                item.file = element.val().file;
                item.fileName = element.val().fileName;
                item.timeStamp = element.val().timeStamp;
                returnArray.push(item);
            });
            console.table(returnArray);
            setDatas(returnArray);
              

            });         
         }
           getUserData(); 

    },[]); 

        let openWindow = (data, data1) => {
          window.location.href = "mailto:" + data + "?subject=Reply For Enquiry&body&body="+data1;
    }

    let logOut = e => {
      signOut(auth);
       window.location.href = '/adminpage';
       }

       let dateFormatss = (date) => {
        var data = moment.unix(date).format("DD-MM-YYYY hh:mm:ss A");
       // var data = moment.unix(date).format('dddd, MMMM Do, YYYY h:mm:ss A')
        return data;
      }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("./../images/F Complete.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./../images/O.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./../images/U.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      <img src={require("./../images/R.png")} style={{width: '50px', height: '50px'}} alt=""/> &nbsp;
      </div>
        <ul className="nav-links">
          <li>
         
            <a>
          &emsp;  <i className="fa-sharp fa-solid fa-warehouse" style={{color: '#FFFF00'}}></i> &nbsp; &nbsp;
              <span className="links_name">Dashboard</span>
            </a>
   
          </li>

          <li className="log_out">
          <a onClick={logOut} style={{cursor: "pointer"}}>
          &emsp;  <i className="fa-sharp fa-solid fa-right-from-bracket" style={{color: '#FFFF00'}}></i> &nbsp; &nbsp;
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
        </div>
        <div className="search-box">
          <input type="text" style={{fontSize: '14px'}} placeholder="Search by Name & Email & Contact Number..." onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
        </div>
        <div className="profile-details">
        <img src={require("./../images/F Complete.png")} style={{width: '50px', height: '50px'}} alt=""/>
          <span className="admin_name">TCOF Admin</span>
          <i className='bx bx-chevron-down' ></i>
        </div>
      </nav>
  
      <div className="home-content">

      <div className='search_result_list my-2'>
            
            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#212020', color:'#fff'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Name</th>
             <th style={{fontSize: '16px'}}>Email</th>
             <th style={{fontSize: '16px'}}>Contact Number</th>
             <th style={{fontSize: '16px'}}>Nature Of Business</th>
             <th style={{fontSize: '16px'}}>File</th>
             <th style={{fontSize: '16px'}}>Enquiry</th>
             <th style={{fontSize: '16px'}}>Contacted On</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
            {datas.filter(x => x.name.toLowerCase().includes(term.toLowerCase()) || x.email.toLowerCase().includes(term.toLowerCase()) || x.contactNumber.toLowerCase().includes(term.toLowerCase())).map((persons,index) =>
    
           <tr key={index}>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.name}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.email}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.contactNumber}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.natureOfBusiness}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}><button className='btn btn-sm' data-placement="bottom" title="View Report" data-toggle="modal" data-target="#viewReportModal" 
             onClick={() => {
              setUrlData(persons.file); setUrlName(persons.fileName)}}>{persons.fileName}</button></td>
                 <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.enquiry}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{dateFormatss(persons.timeStamp)}</td>
             <td style={{verticalAlign: 'middle'}}>
            <a onClick={() => openWindow(persons.email, persons.enquiry)}>
              <i className="fa-sharp fa-solid fa-square-envelope" style={{fontSize: '24px', color: '#FFFF00'}} aria-hidden="true"></i>
              </a>
                     </td>
           </tr>
       
          )}
</tbody>
</table>
       </div>
        </div>

        <div className="modal fade bd-example-modal-lg" id="viewReportModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-width" role="document">
              <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel" style={{fontSize:'20px',fontFamily:'poppins Semibold', color:'#263579'}}>{urlName}</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>  
      
                    
                    <div className="modal-body" style={{overflowY: 'scroll', minHeight: '450px', maxHeight: '450px', width: '100%'}}>


<div>
   <iframe src={urlData} style={{minHeight: '450px', maxHeight: '450px', width:'100%'}}/>
          </div>

             </div>
                
                    <div className="modal-footer">
                      <button type="button" className="btn btn-sm btn-danger" data-dismiss="modal" style={{letterSpacing: '.3px',fontSize:'16px',fontFamily:'poppins medium'}}>Close</button>
              
                    </div>
                  </div>
              </div>
              </div>

          </div>
       

        </section> 
</section>
        );

    }
    export default ContactView;